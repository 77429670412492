<template>
    <div :class="['card', autoWidth ? 'card-block': '', isHighlighted]" @click="highlight">
        <div class="card-content">
            <div class="card-content__top">
                <span :class="['category', 'h5', `text-${color}`]">{{ cat }}</span>
                <card-share-button :link="link"/>
                <bookmark-button  :parentClass="'card-body-icon'" :content="content" v-if="content!==null"/>
            </div>
            <h3 class="title">{{ title }}</h3>
            <p class="desc" v-if="shortDescription" v-html="shortDescription"></p>
        </div>
        <router-link :to="link" :title="linkToTitle" :target="openNewTab" class="btn btn-more mt-auto card-hover">
            Mehr Informationen
            <span class="material-icons-outlined show-ltr" aria-hidden="true">navigate_next</span>
            <span class="material-icons-outlined show-rtl" aria-hidden="true">navigate_before</span>    
        </router-link>
    </div>
</template>

<script>
  import { getFieldValues } from '@/utils/helpers';
  import CardShareButton from '@/components/controls/CardShareButton.vue';

  export default {
    name: 'RatgeberCard',
    components: {
        BookmarkButton: () => import('@/components/controls/BookmarkButton'),
        CardShareButton,
    },
    props: {
        content: {
            type: Object,
            required: true
        },
        category: {
            type: String,
            default: '',
        },
        autoWidth: {
            type: Boolean,
            default: false,
        },
        target: {
            type: String,
            default: "list",
        },
        openInNewTab: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        openNewTab(){
            if(this.openInNewTab) {
                return "_blank";
            }
            return "_self";
        },
        link(){
            var cat = "default";
            if(this.cat!==null && this.cat!==undefined){
                cat = this.cat.replaceAll(' ', '');
            }
            return "/ratgeber/"+cat+"/"+this.content.id;
        },
        linkToTitle(){
            return "Mehr Informationen zu: "+this.content.title;
        },
        fullLink(){

        },
        color(){
            if(this.cat!==null){
                switch(this.cat){
                    case "Neu in Wolfsburg":
                    return "meganta";
                    break;
                    case "Senior*innen":
                    return "purple";
                    break;
                    case "Eltern":
                    return "jade";
                    break;
                    case "Kinder & Jugendliche":
                    return "orange";
                    break;
                }
            }
            return "black";
        },
        title(){
            var desc = getFieldValues(this.content,'title');
            return desc!=null?desc:'';
        },
        description(){
            var desc = getFieldValues(this.content,'body');
            return desc!=null?desc:'';
        },
        shortDescription() {
            if (this.description.length > 180) {
                this.showMoreDescription = true;

                return this.description.substring(0, 180) + '...';
            }
            return this.description;
        },
        isHighlighted(){
            if(this.content.hasOwnProperty("highlight") && this.content.highlight == true){
                return "highlighted";
            }
            return "not-highlighted"
        },
        cat(){
            if(this.category!==''){
                return this.category;
            }
            var value = getFieldValues(this.content,'kategorie');
            if(value!=null){
                if(Array.isArray(value) && value.length>0){
                    return value[0];
                }
                return value;
            }
            return null;
        },
    },
    methods:{
       highlight(event){
        if(this.target == "map"){
            this.$emit('highlight',{sender:'card', content: this.content});
        }
    }
}
}
</script>

<style lang="scss" scoped>
    @import '../../scss/_variables.scss';

    .card {
        display: flex;
        position: relative;
        border: none;
        border-radius: 0px;
        margin: 10px 0;
        margin-right: 15px;
        min-width: 290px;
        max-width: 380px;
        box-shadow: 1px 2px 10px 1px #00000040;

        &.highlighted{
            border: 3px solid $primary;
        }

        &-content {
            padding: 20px;
            flex: 1;

            &__top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;

                .category {
                    color: $primary;
                    font-size: 14px;
                }
            }

            .title {
                font-size: 16px;
                line-height: 24px;
                margin-top: 10px;
                min-height: 52px;
            }

            .desc {
                line-height: 20px;
                min-height: 68px;
                margin-bottom: 10px;
                font-size: 14px;
            }
        }

        &:first-child {
            margin-left: 15px;
        }

        &-block {
            max-width: 100%;
            width: 100%;
            margin-right: 0;
            margin-bottom: 15px;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .h5 {
        font-weight: 600;
        margin-bottom: 0;
    }

    body[dir="rtl"] {
        .card {
            margin-right: 0;
            margin-left: 15px;

            &:first-child {
                margin-right: 15px;
            }

            &.card-block {
                margin-left: 0;

                &:first-child{
                    margin-right: 0;
                }
            }
        }
    }
</style>
